// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-button {
    background-color: #007bff;
    color: white;
    border-color: black;
    padding: 10px 20px;
    margin: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
.main-button:hover {
    background-color: #0056b3; 
    transform: scale(1.05); 
}
  
.main-button:active {
    background-color: #004080;
    transform: scale(0.98);
}
  
.main-button:focus {
    outline: none;
    box-shadow: 0 0 5px #007bff;
}

.danger-button {
    background-color: #dc3545; /* Bootstrap's red/danger color */
    color: white;
    border-color: black;
    padding: 10px 20px;
    margin: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .danger-button:hover {
    background-color: #c82333; /* Darker red on hover */
    transform: scale(1.05); 
  }
  
  .danger-button:active {
    background-color: #bd2130; /* Even darker red on click */
    transform: scale(0.98);
  }
  
  .danger-button:focus {
    outline: none;
    box-shadow: 0 0 5px #dc3545;
  }`, "",{"version":3,"sources":["webpack://./src/styles/buttons.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,2DAA2D;EAC7D;;AAEF;IACI,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB,EAAE,iCAAiC;IAC5D,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,2DAA2D;EAC7D;;EAEA;IACE,yBAAyB,EAAE,wBAAwB;IACnD,sBAAsB;EACxB;;EAEA;IACE,yBAAyB,EAAE,6BAA6B;IACxD,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,2BAA2B;EAC7B","sourcesContent":[".main-button {\n    background-color: #007bff;\n    color: white;\n    border-color: black;\n    padding: 10px 20px;\n    margin: 2px 10px;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 16px;\n    transition: background-color 0.3s ease, transform 0.2s ease;\n  }\n  \n.main-button:hover {\n    background-color: #0056b3; \n    transform: scale(1.05); \n}\n  \n.main-button:active {\n    background-color: #004080;\n    transform: scale(0.98);\n}\n  \n.main-button:focus {\n    outline: none;\n    box-shadow: 0 0 5px #007bff;\n}\n\n.danger-button {\n    background-color: #dc3545; /* Bootstrap's red/danger color */\n    color: white;\n    border-color: black;\n    padding: 10px 20px;\n    margin: 2px 10px;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 16px;\n    transition: background-color 0.3s ease, transform 0.2s ease;\n  }\n  \n  .danger-button:hover {\n    background-color: #c82333; /* Darker red on hover */\n    transform: scale(1.05); \n  }\n  \n  .danger-button:active {\n    background-color: #bd2130; /* Even darker red on click */\n    transform: scale(0.98);\n  }\n  \n  .danger-button:focus {\n    outline: none;\n    box-shadow: 0 0 5px #dc3545;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
