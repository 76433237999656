import React, { useEffect, useState } from "react";
import { deleteAllWaterQualityData, getWaterQualityData, retrieveWaterQualityDataFromDataService } from "../services/waterAPIService";
import '../styles/table.css';
import '../styles/buttons.css';

const WaterQualityTable = () => {
    
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [databaseUpdateTrigger, setDatabaseUpdateTrigger] = useState(0);

  //TODO: Remove this and related code when python script is updated to cron job or similar thingie
  const getWaterQualityDataFromService = async () => {
    try{
      setLoading(true)
      const response = await retrieveWaterQualityDataFromDataService();
      console.log(response);
      setDatabaseUpdateTrigger(prev => prev + 1);
    } catch (err){
      console.error('Error retrieving data from data service:', err);
      setError("Calling data service script failed. Please refresh and try again")
    } finally{
      setLoading(false)
    }
  }

  const clearDatabaseOfWaterQuality = async () => {
    try {
      setLoading(true)
      const response = await deleteAllWaterQualityData();
      console.log(response);
      setDatabaseUpdateTrigger(databaseUpdateTrigger + 1);
    } catch (err){
      console.error('Error deleting all water quality data:', err);
      setError("Failed to clear the database of water quality")
    } finally {
      setLoading(false);
    }
  }

  //fetches the data from the BE/DB
  useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const fetchedData = await getWaterQualityData();
          if (Array.isArray(fetchedData)) {
            setData(fetchedData);
          } else {
            setError('Failed to fetch data');
          }
        } catch (err) {
          setError('Failed to fetch data');
          console.error('Error fetching data:', err);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }, [databaseUpdateTrigger]);

    return (
      <>
        <h2>Water Quality Table</h2>
        <br/>
        
        <button onClick={getWaterQualityDataFromService} className="main-button">Fetch Water Quality Data</button>
        <button onClick={clearDatabaseOfWaterQuality} className="danger-button">Delete ALL Data</button>

        <div className="table-container">
          {loading && <p>Loading...</p>}
          {error && <p>An error occured! {error}</p>}

          {data && data.length > 0 && ( 
            <table className="table-water-quality">
              <thead>
                <tr>
                  <th className="table-header">Lake Name</th>
                  <th className="table-header">Station Code</th>
                  <th className="table-header">Sample Date</th>
                  <th className="table-header">pH</th>
                  <th className="table-header">Conductance</th>
                  <th className="table-header">Alkalinity</th>
                  <th className="table-header">Chloride</th>
                </tr>
              </thead>
              <tbody>
                {data.map((entry) => (
                  <tr key={entry.id}>
                    <td className="table-cell">{entry.lakeName}</td>
                    <td className="table-cell">{entry.stationCode}</td>
                    <td className="table-cell">{entry.sampleDate}</td>
                    <td className="table-cell">{entry.ph}</td>
                    <td className="table-cell">{entry.conductance}</td>
                    <td className="table-cell">{entry.alkalinity}</td>
                    <td className="table-cell">{entry.chloride}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {data.length <= 0 && loading === false && (
            <p>Sorry! There's currently no data to display. Try pulling some by clicking the above button!</p>
          )}
        </div>

      </>
    );

};

export default WaterQualityTable;