import React, { useState }  from 'react';
import WaterQualityTable from './components/WaterQualityTable';
import WasteWaterTable from './components/WasteWaterTable';
import './styles/buttons.css';

const App: React.FC = () => {

  const [selectedTable, setSelectedTable] = useState<string>("");

  return (
    <div
      className="App"
    >
      <h1>Watershed Quality and Industrial Discharge Dashboard</h1>
      <p>Maintaining safe and clean drinking water is an ever growing challenge. With the environmental industry struggling to track risk levels, 
        we will introduce WaterWise! WaterWise is a dashboard that will monitor and analyze industrial wastewater and 
        stream/lake water quality in relation to the quality of water at drinking inlets. 
        You can view graphs, data over various periods of time and post your own water quality risks within your community!
      </p>
      <p>
        To get started, select a table to view using the buttons below.
      </p>
      
      {/* We have buttons here to flip between tables.*/}
      <button onClick={() => setSelectedTable("waterQuality")} className='main-button'>Water Quality</button>
      <button onClick={() => setSelectedTable("wasteWater")} className='main-button'>Waste Water</button>

      {selectedTable === "wasteWater" && <WasteWaterTable/>}
      {selectedTable === "waterQuality" && <WaterQualityTable/>}
    </div>
  );
};

export default App;
