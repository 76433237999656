import React, { useEffect, useState } from "react";
import { deleteAllWasteWaterData, getWasteWaterData, retrieveWasteWaterDataFromDataService} from "../services/waterAPIService";
import '../styles/table.css';
import '../styles/buttons.css';

const WasteWaterTable = () => {
    
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [databaseUpdateTrigger, setDatabaseUpdateTrigger] = useState(0);

  //TODO: Remove this and related code when python script is updated to cron job or similar thingie
  //Fetching waste water does take significant time
  const getWasteWaterDataFromService = async () => {
    try{
      setLoading(true)
      const response = await retrieveWasteWaterDataFromDataService();
      console.log(response);
      setDatabaseUpdateTrigger(prev => prev + 1);
    } catch (err){
      console.error('Error retrieving data from data service:', err);
      setError("Calling data service script failed. Please refresh and try again")
    } finally{
      setLoading(false)
    }
  }

  const clearDatabaseOfWasteWater = async () => {
    try {
      setLoading(true)
      const response = await deleteAllWasteWaterData();
      console.log(response);
      setDatabaseUpdateTrigger(databaseUpdateTrigger + 1);
    } catch (err){
      console.error('Error deleting all notes:', err);
      setError("Failed to clear the database of waste water data")
    } finally {
      setLoading(false);
    }
  }

  //fetches the data from the BE/DB for waste water
  useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const fetchedData = await getWasteWaterData();
          if (Array.isArray(fetchedData)) {
            setData(fetchedData);
          } else {
            setError('Failed to fetch notes');
          }
        } catch (err) {
          setError('Failed to fetch data');
          console.error('Error fetching data:', err);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }, [databaseUpdateTrigger]);

    return (
      <>
        <h2>Waste Water Table</h2>
        <br/>
        <p>Quick note, the Waste water will take a few minutes to fetch, please be patient (future updates will have this automated!)</p>
        <button onClick={getWasteWaterDataFromService} className="main-button">Fetch Waste Water Data</button>
        <button onClick={clearDatabaseOfWasteWater} className="danger-button">Delete ALL Data</button>

        <div className="table-container">
          {loading && <p>Loading...</p>}
          {error && <p>An error occured! {error}</p>}

          {data && data.length > 0 && ( 
            <table className="table-water-quality">
              <thead>
                <tr>
                  <th className="table-header">Work Name</th>
                  <th className="table-header">Control Point ID</th>
                  <th className="table-header">Component Type</th>
                  <th className="table-header">Sample Date</th>
                  <th className="table-header">Sector</th>
                  <th className="table-header">Value</th>
                  <th className="table-header">Frequency</th>
                </tr>
              </thead>
              <tbody>
                {data.map((entry) => (
                  <tr key={entry.id}>
                    <td className="table-cell">{entry.worksName}</td>
                    <td className="table-cell">{entry.controlPointId}</td>
                    <td className="table-cell">{entry.componentType}</td>
                    <td className="table-cell">{entry.sampleDate}</td>
                    <td className="table-cell">{entry.sector}</td>
                    <td className="table-cell">{`${entry.value}\n${entry.unitOfMeasure}`}</td>
                    <td className="table-cell">{entry.frequency}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {data.length <= 0 && loading === false && (
            <p>Sorry! There's currently no data to display. Try pulling some by clicking the above button!</p>
          )}
        </div>
      </>
    );

};

export default WasteWaterTable;